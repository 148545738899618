<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <material-card
          color="primary"
          icon="mdi-account-outline"
        >
          <template #title>
            Передзамовлення: <b>{{ order.id }}</b> <br>
            <span class="caption">{{ order.date | formatDateTime }}</span>
          </template>

          <ButtonComeBack class="ml-3" />

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="7">
                  <material-card>
                    <v-col cols="12">
                      <v-autocomplete 
                        color="purple"
                        return-object
                        no-filter
                        hide-no-data
                        auto-select-first
                        no-data-text="Нічого не знайдено."
                        label="Місто"
                        item-text="name"
                        item-value="name"
                        v-model="data.city"
                        :items="cities"
                        :search-input.sync="searchCity"
                        :readonly="!(isAdmin || isManager)"
                        >
                      </v-autocomplete>
                    </v-col>

                    <v-col cols="12">
                      <v-autocomplete 
                        color="purple"
                        no-filter
                        hide-no-data
                        return-object
                        auto-select-first
                        no-data-text="Нічого не знайдено."
                        label="Тип доставки"
                        item-text="name"
                        item-value="name"
                        v-model="data.delivery"
                        :items="delivery"
                        :readonly="!(isAdmin || isManager)"
                        >
                      </v-autocomplete>
                    </v-col>

                    <v-col v-if="!order.delivery_payload" cols="12">
                      <p class="red--text">Доставку в замовленні не вказано.<br> Зверніться до адміністраторів.</p>
                    </v-col>

                    <template v-else>
                      <v-col v-if="data.delivery.id === 2" cols="12">
                        <v-autocomplete 
                          color="purple"
                          no-filter
                          hide-no-data
                          return-object
                          auto-select-first
                          no-data-text="Нічого не знайдено."
                          label="На відділення Нової Пошти"
                          item-text="address"
                          item-value="address"
                          v-model="data.postNP"
                          :items="postNP"
                          :search-input.sync="searchPostNP"
                          :readonly="!(isAdmin || isManager)"
                          >
                        </v-autocomplete>
                      </v-col>

                      <v-col v-if="data.delivery.id === 3" cols="12">
                        <v-autocomplete 
                          color="purple"
                          no-filter
                          hide-no-data
                          return-object
                          auto-select-first
                          no-data-text="Нічого не знайдено."
                          label="Забрати з магазину Техномережі F5"
                          item-text="address_ukr"
                          item-value="address"
                          v-model="data.postTTF5"
                          :items="postTTF5"
                          :readonly="!(isAdmin || isManager)"
                          >
                        </v-autocomplete>
                      </v-col>

                      <template v-if="data.delivery.id === 1">
                        <v-container>
                          <v-row>
                            <v-col cols="12" md="4">
                              <v-text-field
                                color="purple"
                                label="Вулиця"
                                v-model="data.street"
                                :readonly="!(isAdmin || isManager)"
                              />
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-text-field
                                color="purple"
                                label="Будинок"
                                v-model="data.house"
                                :readonly="!(isAdmin || isManager)"
                              />
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-text-field
                                color="purple"
                                label="Квартира"
                                v-model="data.apartment"
                                :readonly="!(isAdmin || isManager)"
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </template>
                    </template>
                  </material-card>
                </v-col>

                <v-col cols="12" md="5">
                  <material-card>
  
                  <v-col cols="12">
                    <v-text-field
                      color="purple"
                      label="ПІБ клієнта"
                      v-model="data.full_name"
                      :readonly="!(isAdmin || isManager)"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      color="purple"
                      label="Телефон клієнта"
                      v-model="data.phone"
                      :readonly="!(isAdmin || isManager)"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      color="purple"
                      label="E-mail клієнта"
                      v-model="data.email"
                      :readonly="!(isAdmin || isManager)"
                    />
                  </v-col>
                  </material-card>
                </v-col>
              </v-row>

              <v-row v-if="order.product && order.product.length">
                <v-col cols="12">
                  <material-card>
                    <template #title>
                      <h3 class="text-h4 mt-6">Товари передзамовлення</h3>
                    </template>

                    <v-col v-if="order.product && order.product.length" cols="12">
                      <v-simple-table class="mb-6">
                        <thead>
                          <tr>
                            <th class="primary--text">
                              Код Тайфун
                            </th>
                            <th class="primary--text">
                              Товар
                            </th>
                            <th class="primary--text">
                              Акція
                            </th>
                            <th class="primary--text">
                              Ціна
                            </th>
                            <th class="primary--text">
                              Кількість
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr v-for="(product, index) in order.product" :key="`order-product-${index}`">
                            <td>{{ product.id || '-' }}</td>
                            <td>{{ order.product_name || '-' }}</td>
                            <td>{{ product.id_action || '-' }}</td>
                            <td>{{ order.price | numFormat }} грн</td>
                            <td>{{ order.quantity || '-' }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                  </material-card>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="5">
                  <material-card>
                    <v-col cols="12">
                      <v-text-field
                        color="purple"
                        label="Коментар покупця"
                        v-model="data.comment"
                        :readonly="true"
                      />
                    </v-col>
                  </material-card>
                </v-col>
                
                <v-col cols="12" md="7">
                  <material-card>
                    <!-- <template #title>
                      <h3 class="text-h4">Коментарі</h3>
                    </template> -->

                    <v-col cols="12">
                      <v-text-field
                        color="purple"
                        label="Коментар"
                        v-model="comment"
                        :readonly="!(isAdmin || isManager)"
                      />
                    </v-col>
                    <v-col v-if="comment.length" cols="12" class="text-right pt-0" >
                      <v-btn
                        color="primary" min-width="150"
                        @click="sendComment"
                      >
                        Додати коментар
                      </v-btn>
                    </v-col>

                    <template>
                      <v-col cols="12" v-for="(comment, index) in order.comments" :key="`comment-${index}`">
                        <v-card class="mx-auto">
                          <v-card-text>
                            <div class="text--primary">
                              {{ comment.comments }}
                            </div>
                            <div class="d-flex">
                              <p v-if="comment.user_name" class="mt-2 mb-0 mr-4">{{ comment.user_name }}</p>
                              <p class="mt-2 mb-0">{{ comment.datetime | formatDateTime }}</p>
                            </div>
                            <div class="comment-remove" @click="onDialogComment(comment.id)">
                              <v-icon
                                class="mx-1"
                                color="error"
                              >
                                mdi-close
                              </v-icon>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </template>
                  </material-card>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="text-right" >
                  <v-btn color="primary" min-width="150" @click="updateOrder">
                    Зберегти
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </material-card>
      </v-col>

    </v-row>

    <v-dialog v-model="dialogComment" max-width="400">
      <v-card>
        <v-card-title class="text-h5 align-center">
          Ви дійсно хочете видалити коментар?
        </v-card-title>
        <v-card-actions class="flex justify-center">
          <v-btn color="primary" min-width="150" text @click="onRemoveComment">
            Так
          </v-btn>
          <v-btn color="grey" min-width="150" text @click="closeDialog">
            Нi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
    HTTP,
    CHECKOUT
} from '../http-common';
import { mapGetters, mapMutations } from 'vuex'

  export default { 
    name: 'UserProfileView',
    data() {
      return {
        order: {},
        data: {
          full_name: null,
          phone: null,
          email: null,
          comment: null,
          city: null,
          delivery: {},
          postNP: null,
          postTTF5: null,
          street: null,
          house: null,
          apartment: null,
        },
        searchCity: null,
        searchPostNP: null,
        cities: [],
        postNP: [],
        postTTF5: [],
        comment: '',
        dialogComment: false,
        commentId: null
      }
    },
    computed: {
      ...mapGetters(['isAdmin', 'isManager', 'snackbar', 'status', 'types', 'delivery', 'orders'])
    },
    methods: {
      ...mapMutations(['setSnackbar']),
      getPreorder() {
        HTTP.post('/get-preorder', {
          'id': this.$route.params.id 
        })
          .then(({ data }) => {
            this.order = data.content || {};
            this.setData(); 
          })
          .catch((error) => {
            this.setSnackbar({ type: 'error', text: error.message });
          })
      },
      updateOrder() {
        let delivery_payload;

        if (this.data.delivery.id === 1) {
          delivery_payload = {
            street: this.data.street,
            house: this.data.house,
            apartment: this.data.apartment
          }
        }
        if (this.data.delivery.id === 2) {
          delivery_payload = this.data.postNP
        }
        if (this.data.delivery.id === 3) {
          delivery_payload = this.data.postTTF5
        }

        const data = {
          "id": this.order.id,
          "delivery_id": this.data.delivery.id,
          "full_name": this.data.full_name,
          "phone": this.data.phone,
          "email": this.data.email,
          "city_id": this.data.city.id || null,
          "delivery_payload": delivery_payload || null,
          "comment": this.data.comment,
        }

        HTTP.post('/update-preorder', data)
          .then(() => {
            this.setSnackbar({ type: 'success', text: 'Успiшно збережено' });
            this.$router.back();
          })
          .catch((error) => {
            this.setSnackbar({ type: 'error', text: error.message });
          })
      },
      setData() {
        if (this.order) {
          const initCity = { id: this.order.city_id, name: this.order.city_name, typhoon_id: this.order.city_typhoon_id };

          this.data.full_name = this.order.full_name || null;
          this.data.phone = this.order.phone || null;
          this.data.email = this.order['e-mail'] || null;
          this.data.comment = this.order.comment || null;
          this.data.city = initCity;
          this.cities.push(initCity);
          this.data.delivery = { id: this.order.delivery_id, name: this.order.delivery_naem };

          if(this.order.delivery_id === 1 && this.order.delivery_payload) {
            this.data.street = this.order.delivery_payload.street || null;
            this.data.house = this.order.delivery_payload.house || null;
            this.data.apartment = this.order.delivery_payload.apartment || null;
          }

          if(this.order.delivery_id === 2 && this.order.delivery_payload) {
            const initPostNP = { 
              id: this.order.delivery_payload.id || null,
              address: this.order.delivery_payload.address || null  
            }
            this.data.postNP = initPostNP;
            this.postNP.push(initPostNP);
          }

          if(this.order.delivery_id === 3 && this.order.delivery_payload) {
            const initPostTTF5 = { 
              id: this.order.delivery_payload.id || null, 
              address_ukr: this.order.delivery_payload.address || null,
              contrator: this.order.delivery_payload.contrator || null
            }
            this.data.postTTF5 = initPostTTF5;
          }
        }
      },
      onCities(val) {
        if (val) {
          CHECKOUT.post('/cities', {
            name: val,
            lang: 'ua'
          },
          {
            headers: { 
              'server-name': 'f5.ua' 
            }
          })
            .then(({ data }) => {
              this.cities = data.content.cities;
            })
            .catch((error) => {
              this.setSnackbar({ type: 'error', text: error.message });
            })
          }
      },
      onPostNP(val) {
        if (val) {
          CHECKOUT.post('/delivery-np', {
            city: this.data.city.typhoon_id || null,
            name: val
          },
          {
            headers: { 
              'server-name': 'f5.ua' 
            }
          })
            .then(({ data }) => {
              this.postNP = data.content.np_office;
            })
            .catch((error) => {
              this.setSnackbar({ type: 'error', text: error.message });
            })
          }
      },
      onPostTTF5() {
        CHECKOUT.post('/delivery-ttf5', {
          city: this.data.city.id || null
        },
        {
          headers: { 
            'server-name': 'f5.ua' 
          }
        })
          .then(({ data }) => {
            this.postTTF5 = data.content.shops_f5;
          })
          .catch((error) => {
            this.setSnackbar({ type: 'error', text: error.message });
          })
      },
      sendComment() {
        HTTP.post('/create-comment-preorder', {
          'preorder_id': this.order.id,
          'comments': this.comment,
        })
          .then(() => {
            this.comment = '';
            this.getPreorder();
          })
          .catch((error) => {
            this.setSnackbar({ type: 'error', text: error.message });
          })
      },
      onRemoveComment(id) {
        this.dialogComment = false
        HTTP.post('/delete-comment-preorder', {
          'id': this.commentId
        })
          .then(() => {
            this.comment = '';
            this.setSnackbar({ type: 'success', text: 'Успiшно видалено' });
            this.getPreorder();
          })
          .catch((error) => {
            this.setSnackbar({ type: 'error', text: error.message });
          })
      },
      onDialogComment(id) {
        this.dialogComment = true
        this.commentId = id
      },
      closeDialog() {
        this.dialogComment = false 
        this.commentId = null
      }
    },
    watch: {
      searchCity(val) {
        if (val && val !== this.order.city_name) {
          this.onCities(val)
          this.data.postNP = null
          this.data.postTTF5 = null
        } else {
          this.cities = []
          this.cities.push({ name: this.order.city_name })
        }
      },
      searchPostNP(val) {
        if (val) {
          this.onPostNP(val)
        } else {
          this.postNP = []
        }
      },
      'data.delivery': {
        handler({ id }) {
          if(id === 3) {
            this.onPostTTF5()
          }
        },
        immediate: false
      },
      'data.city.id': {
        handler(id) {
          if(this.data.delivery.id === 3) {
            this.onPostTTF5()
          }
        },
        immediate: false
      },
    },
    mounted() {
      this.getPreorder();
    }
  }
</script>

<style scoped>
  .comment-remove {
    position: absolute;
    top: 8px;
    right: 5px;
    cursor: pointer;
  }
</style>
